import React, { Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, connect, GLGlobal } from 'gl-commonui';
import { SecondaryTitle } from '@app/components';
import { LocalProductDetail } from './components/local-detail';
import { StateType } from '@app/states';
import { getCandidateProducts, createLocalProduct, goToLocalProductList, getRegions } from '@app/states/admin/product';
import { GlobalProductModel, LocalProductModel } from '@app/service/admin/products';
import { RegionModel } from '@app/service/admin/regions';
import { SchoolCurriculumType } from '@app/util';
import { GSAdminLocale } from '@app/locales/localeid';

interface LocalProductNewPageProps {
    globalProducts?: GlobalProductModel[]
    candidateLocalProducts?: LocalProductModel[]
    regions?: RegionModel[]
    getCandidateProducts: (regionId) => void
    createLocalProduct: (product) => void
    getRegions: () => void
    goToLocalProductList: () => void
}

@withRouter
@connect(({ product: { globalList: globalProducts, localCandidateList: candidateLocalProducts, regions } }: StateType) => ({
    globalProducts,
    candidateLocalProducts,
    regions
}), {
        getCandidateProducts,
        createLocalProduct,
        goToLocalProductList,
        getRegions
    })
export class LocalProductNewPage extends Component<RouteComponentProps<any> & LocalProductNewPageProps> {
    componentDidMount() {
        this.props.getRegions();
        this.props.getCandidateProducts(null);
        
    }
    onRegionChanged(regionId) {
        this.props.getCandidateProducts(regionId);
    }
    render() {
        const { globalProducts, candidateLocalProducts, createLocalProduct, goToLocalProductList, regions } = this.props;
        return (
            <div className='content-layout'>
                <div className="product-detail product-detail-edit">
                    <SecondaryTitle title={GSAdminLocale.LocalProductNewTitle} />
                    <LocalProductDetail
                        model = {{licenseType: parseInt(SchoolCurriculumType.None, 10)}}
                        regions = {regions}
                        globalProducts={globalProducts.filter(gp=>!gp.disabled)}
                        candidateLocalProducts = {candidateLocalProducts}
                        onRegionChanged={this.onRegionChanged.bind(this)}
                        handleSubmit={(product) => createLocalProduct(product)} 
                        gotoList={goToLocalProductList}
                    />
                </div>
            </div>
        )
    }
}